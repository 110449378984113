import React from "react";

interface VideoProps {
    videoSrcURL: string;
    videoTitle: string;
    allow?: string;
    height?: string;
    width?: string; 
    name?: string;
}

const Video = ({ videoSrcURL, videoTitle, allow, height, width, name }: VideoProps) => (
    <div>
        <iframe
            src={videoSrcURL}
            title={videoTitle}
            allow={allow}
            height={height}
            width={width}
            loading="eager"
            name={name}
            referrerPolicy="no-referrer"
        />
    </div>
);
export default Video;
