import React from "react";
import styled from "styled-components";
import Video from "../components/video";

export const PageContainer = styled.div`
  box-sizing: border-box;
  font-family: "Roboto Mono", monospace;
  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentContainer = styled(Container)`
  margin-top: 80px;
`;

const BioContainer = styled.div`
  width: 380px;
  margin-bottom: 50px;
  text-align: justify;
  @media (max-width: 600px) {
    width: 280px;
  }
`;

const Name = styled.h1`
  font-size: 27px;
  @media (max-width: 600px) {
    font-size: 20px;
  }
`;

const Profession = styled.h2`
  margin-top: -10px;
  font-size: 20px;
  color: grey;
  @media (max-width: 600px) {
    font-size: 20px;
  }
`;

const VideoContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 25px;
`;

export default function Home() {
  return (
    <PageContainer>
      <ContentContainer>
        <Container>
          <Name>Christopher Müller-Uibrig</Name>
          <Profession>Musician and Songwriter</Profession>
        </Container>
        <VideoContainer>
          <Video
            name="Time will tell"
            width="280px"
            height="215px"
            videoSrcURL="https://www.youtube.com/embed/rAFk6AOSU0c"
            videoTitle="Time will tell"
          />
        </VideoContainer>
        <BioContainer>
          Hi, my name is Christopher, I'm a Berin-based chemist, musician and
          songwriter. Find my work on {" "}
          <a    
            rel="noopener noreferrer"
            style={{ color: "grey" }}
            href="https://open.spotify.com/album/388H6iYugn3JozArxflbOw?si=S9d3pqMySaGSqRkP_HDsiQ"
          >
            spotify
          </a>{" "}
          <a
            rel="noopener noreferrer"
            style={{ color: "grey" }}
            href="https://soundcloud.com/user-307518773/"
          >
            soundcloud
          </a>
          ,{" "}
          <a  rel="noopener noreferrer" style={{ color: "grey" }} href="https://uibrig.bandcamp.com/">
            bandcamp
          </a>{" "}
          and{" "}
          <a
            rel="noopener noreferrer"
            style={{ color: "grey" }}
            href="https://www.youtube.com/channel/UC1BrzBnkSkjgI-7VEcQCJHg"
          >
            YouTube
          </a>
          . Follow me on{" "}
          <a
            rel="noopener noreferrer"
            style={{ color: "grey" }}
            href="https://www.instagram.com/chris_muelleruibrig/"
          >
            instagram
          </a>{" "}
          for mundane snippets of my songwriting and music. If you're interested
          in collaborating with me, feel free to{" "}
          <a style={{ color: "grey" }} href="mailto:uibrig191@gmail.com">
            reach out
          </a>
          .
        </BioContainer>
      </ContentContainer>
    </PageContainer>
  );
}
